
import mdiWeb from "~/assets/images/icon/icon-language.svg?inline";
import mdiArrow from "~/assets/images/icon/icon-bottom-arrow.svg?inline";

export default {
  name: "Language",

  components: {
    mdiWeb,
    mdiArrow
  },

  props: {
    langType: {
      type: String,
      require: true,
      default: "desktop"
    }
  },

  data () {
    return {
      langMenuOpen: false,

      defaultLang: {
        lang: "영문",
        locale: "en"
      },

      languages: [
        {
          id: 1,
          lang: "한국어",
          locale: "ko"
        },
        {
          id: 2,
          lang: "English",
          locale: "en"
        }
        // {
        //   id: 3,
        //   lang: "Japanese",
        //   locale: "ja"
        // }
      ],

      languagesM: [
        {
          id: 1,
          lang: "한국어",
          locale: "ko"
        },
        {
          id: 2,
          lang: "ENG",
          locale: "en"
        }
        // {
        //   id: 3,
        //   lang: "Jpn",
        //   locale: "ja"
        // }
      ]
    };
  },

  created () {
    // 최초 접속 시 쿠키 확인 후 언어 설정
    const savedLang = this.$cookies.get('i18n_redirected')
    if (savedLang === 'ko') {
      this.$i18n.locale = 'ko'
    } else {
      // 쿠키가 'ko'이거나 쿠키가 없는 경우
      this.$i18n.locale = 'en'
      // 최초 접속이므로 쿠키를 설정해줍니다.
      this.$cookies.set('i18n_redirected', 'en', {
        path: '/',
        maxAge: 60 * 60 * 24 * 7, // 쿠키 유효기간 설정 (예: 1주일)
        sameSite:'lax'
      })
    }
  },

  methods: {
    toggleLangMenu () {
      this.langMenuOpen = !this.langMenuOpen;
    },

    // changeLanguage (language) {
    //   this.defaultLang.lang = language.lang;
    //   this.defaultLang.locale = language.locale;
    //   this.$i18n.locale = this.defaultLang.locale;
    //   this.$emit("setEn", this.$i18n.locale);
    // }

    changeLanguage (language) {
      this.defaultLang.lang = language.lang;
      this.defaultLang.locale = language.locale;
      this.$i18n.locale = this.defaultLang.locale;
      this.$cookies.set('i18n_redirected', this.defaultLang.locale, {
        path: '/',
        maxAge: 60 * 60 * 24 * 7, // 쿠키 유효기간 설정 (예: 1주일)
        sameSite:'lax'
      });
      this.$emit("setEn", this.$i18n.locale);
    }
  }
};
