
import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'
import 'swiper/swiper-bundle.css'
export default {
  name: 'VariousTemplate',

  data() {
    return {
      templateOption01: {
        modules: [Autoplay],
        centeredSlides: true,
        autoHeight: true,
        autoplay: {
          delay: 0,
          disableOnInteraction: false
        },
        speed: 20000,
        loop: true,
        loopAdditionalSlides: 1,
        breakpoints: {
          '@0.00': {
            slidesPerView: 2.8,
            spaceBetween: 10
          },
          '@0.75': {
            slidesPerView: 3.8,
            spaceBetween: 10
          },
          '@1.00': {
            slidesPerView: 3.8,
            spaceBetween: 24
          },
          '@1.50': {
            slidesPerView: 5.8,
            spaceBetween: 24
          }
        }
      },

      templateImages01: [
        {
          id: 1,
          image: require('~/assets/images/main/template-01.webp')
        },
        {
          id: 2,
          image: require('~/assets/images/main/template-02.webp')
        },
        {
          id: 3,
          image: require('~/assets/images/main/template-03.webp')
        },
        {
          id: 4,
          image: require('~/assets/images/main/template-04.webp')
        },
        {
          id: 5,
          image: require('~/assets/images/main/template-05.webp')
        },
        {
          id: 6,
          image: require('~/assets/images/main/template-06.webp')
        },
        {
          id: 7,
          image: require('~/assets/images/main/template-07.webp')
        },
        {
          id: 8,
          image: require('~/assets/images/main/template-08.webp')
        },
        {
          id: 9,
          image: require('~/assets/images/main/template-09.webp')
        },
        {
          id: 10,
          image: require('~/assets/images/main/template-10.webp')
        },
        {
          id: 11,
          image: require('~/assets/images/main/template-11.webp')
        },
        {
          id: 12,
          image: require('~/assets/images/main/template-12.webp')
        },
        {
          id: 13,
          image: require('~/assets/images/main/template-13.webp')
        },
        {
          id: 14,
          image: require('~/assets/images/main/template-14.webp')
        }
      ],

      templateImages02: [
        {
          id: 1,
          image: require('~/assets/images/main/template-15.webp')
        },
        {
          id: 2,
          image: require('~/assets/images/main/template-16.webp')
        },
        {
          id: 3,
          image: require('~/assets/images/main/template-17.webp')
        },
        {
          id: 4,
          image: require('~/assets/images/main/template-18.webp')
        },
        {
          id: 5,
          image: require('~/assets/images/main/template-19.webp')
        },
        {
          id: 6,
          image: require('~/assets/images/main/template-20.webp')
        },
        {
          id: 7,
          image: require('~/assets/images/main/template-21.webp')
        },
        {
          id: 8,
          image: require('~/assets/images/main/template-22.webp')
        },
        {
          id: 9,
          image: require('~/assets/images/main/template-23.webp')
        },
        {
          id: 10,
          image: require('~/assets/images/main/template-24.webp')
        },
        {
          id: 11,
          image: require('~/assets/images/main/template-25.webp')
        },
        {
          id: 12,
          image: require('~/assets/images/main/template-26.webp')
        },
        {
          id: 13,
          image: require('~/assets/images/main/template-27.webp')
        },
        {
          id: 14,
          image: require('~/assets/images/main/template-28.webp')
        }
      ]
    }
  },
  mounted() {
    this.initializeSwiper()
  },
  methods: {
    initializeSwiper() {
      try {
        const swiper = new Swiper('.swiper-container', this.templateOption01) // Use the specific class of your Swiper container
      } catch (error) {
        console.error('Swiper initialization error:', error)
      }
    }
  }
}
