const text =
  'Visualsyn 비주얼신 - The AI-powered metaverse content creation platform '

export const defHeader = (title, description, keywords, url) => {
  return {
    title,
    titleTemplate: `%s ${text}`,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: description
      },
      {
        hid: 'keywords',
        name: 'keywords',
        content: keywords
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: title,
        template: (chunk) => `${chunk} | ${text}`
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: url
      }
    ],
    link: [
      {
        hid: 'canonical',
        rel: 'canonical',
        href: url
      }
    ]
  }
}
