
export default {
  name: 'FooterMenu',

  methods: {
    scrollToTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
