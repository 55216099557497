
export default {
  name: 'IframeViewer',

  data() {
    return {
      currentURL: '',

      savedScrollTop: 0, // 스크롤 위치 저장 변수

      overlay: false,

      // TODO: 23.08.03 URL 순서 변경
      iframeViewer: [
        {
          url: 'https://dev-share.finiroom.com/dev-demo0'
        },
        {
          url: 'https://dev-share.finiroom.com/dev-demo2'
        },
        {
          url: 'https://dev-share.finiroom.com/dev-demo1'
        }
      ]
    }
  },

  methods: {
    updateIframeURL(index) {
      this.overlay = true
      this.currentURL = this.iframeViewer[index].url
      this.savedScrollTop = window.scrollY // 스크롤 방지
      document.documentElement.style.overflowY = 'hidden'
      document.documentElement.classList.add('positionF')
    },

    deleteIframeURL() {
      this.overlay = false
      this.currentURL = ''
      document.documentElement.style.overflowY = 'inherit' // 스크롤 복원
      document.documentElement.classList.remove('positionF')
    },

    handleIframeScroll() {
      // iframe 내부 스크롤 위치를 기억한 스크롤 위치로 복원
      window.scrollTo(0, this.savedScrollTop)
    }
  }
}
