import { render, staticRenderFns } from "./index.vue?vue&type=template&id=791077e8"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VsHeaderWrap: require('/home/ubuntu/homepage_visualsyn/dist/components/common/header/HeaderWrap.vue').default,VsVisualMain: require('/home/ubuntu/homepage_visualsyn/dist/components/main/VisualMain.vue').default,VsOneStep: require('/home/ubuntu/homepage_visualsyn/dist/components/main/OneStep.vue').default,VsAssetGenerator: require('/home/ubuntu/homepage_visualsyn/dist/components/main/AssetGenerator.vue').default,VsContentsCreator: require('/home/ubuntu/homepage_visualsyn/dist/components/main/ContentsCreator.vue').default,VsVariousTemplate: require('/home/ubuntu/homepage_visualsyn/dist/components/main/VariousTemplate.vue').default,VsIframeViewer: require('/home/ubuntu/homepage_visualsyn/dist/components/main/IframeViewer.vue').default,VsConnectWrap: require('/home/ubuntu/homepage_visualsyn/dist/components/main/ConnectWrap.vue').default,VsContactUs: require('/home/ubuntu/homepage_visualsyn/dist/components/main/ContactUs.vue').default,VsFooterWrap: require('/home/ubuntu/homepage_visualsyn/dist/components/common/footer/FooterWrap.vue').default})
