
export default {
  name: 'MovieWrap',

  data() {
    return {
      localUrl: 'https://cdn.finiroom.com/static/movie/homepageMain.webm',
      localUrl02: 'https://cdn.finiroom.com/static/movie/homepageMain.mp4',
      poster: require('~/assets/images/main/movieThumb.webp'),
      loop: '',
      autoplay: 'muted'
    }
  }
}
