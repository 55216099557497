import { render, staticRenderFns } from "./FooterWrap.vue?vue&type=template&id=53cac44a"
import script from "./FooterWrap.vue?vue&type=script&lang=js"
export * from "./FooterWrap.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VsFooterMenu: require('/home/ubuntu/homepage_visualsyn/dist/components/common/footer/FooterMenu.vue').default,VsFooterAddress: require('/home/ubuntu/homepage_visualsyn/dist/components/common/footer/FooterAddress.vue').default})
