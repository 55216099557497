
export default {
  name: 'ConnectWrap',

  data () {
    return {
      publishing: [
        {
          mImage: require("~/assets/images/main/connect-01-m.webp"),
          image: require("~/assets/images/main/connect-01.webp")
        },
        {
          mImage: require("~/assets/images/main/connect-02-m.webp"),
          image: require("~/assets/images/main/connect-02.webp")
        },
        {
          mImage: require("~/assets/images/main/connect-03-m.webp"),
          image: require("~/assets/images/main/connect-03.webp")
        }
      ],

      interoperability: {
        mImage: require("~/assets/images/main/interoperability-m.webp"),
        image: require("~/assets/images/main/interoperability.webp")
      },

      packaging: {
        mImage: require("~/assets/images/main/packaging-m.webp"),
        image: require("~/assets/images/main/packaging.webp")
      },

      share: {
        mImage: require("~/assets/images/main/share-m.webp"),
        image: require("~/assets/images/main/share.webp")
      }
    }
  }
}
