
export default {
  name: 'ContentsCreator',

  data() {
    return {
      share: {
        mImage: require('~/assets/images/main/creator-bg-m.webp'),
        image: require('~/assets/images/main/creator-bg.webp')
      },

      parallaxHeight: 'auto'
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize() {
      if (this.$i18n.locale === 'ko') {
        if (window.innerWidth < 500) {
          this.parallaxHeight = 1818
          // console.log("500이하 mobile: " + this.parallaxHeight)
        } else if (window.innerWidth < 768) {
          this.parallaxHeight = 1460
          // console.log("768이하 mobile: " + this.parallaxHeight)
        } else if (window.innerWidth < 1280) {
          this.parallaxHeight = 954
          // console.log("1280이하 tab: " + this.parallaxHeight)
        } else if (window.innerWidth < 1500) {
          this.parallaxHeight = 1495
          // console.log("1500이하 pc: " + this.parallaxHeight)
        } else {
          this.parallaxHeight = 1207
          // console.log("1500이상 hd: " + this.parallaxHeight)
        }
      } else if (this.$i18n.locale === 'en') {
        if (window.innerWidth < 320) {
          this.parallaxHeight = 2098
        } else if (window.innerWidth < 393) {
          this.parallaxHeight = 1818
          // console.log("393이하 mobile: " + this.parallaxHeight)
        } else if (window.innerWidth < 450) {
          this.parallaxHeight = 1718
          // console.log("450이하 mobile: " + this.parallaxHeight)
        } else if (window.innerWidth < 500) {
          this.parallaxHeight = 1652
          // console.log("500이하 mobile: " + this.parallaxHeight)
        } else if (window.innerWidth < 768) {
          this.parallaxHeight = 1592
          // console.log("768이하 mobile: " + this.parallaxHeight)
        } else if (window.innerWidth < 1280) {
          this.parallaxHeight = 998
          // console.log("1280이하 tab: " + this.parallaxHeight)
        } else if (window.innerWidth < 1500) {
          this.parallaxHeight = 1603
          // console.log("1500이하 pc: " + this.parallaxHeight)
        } else {
          this.parallaxHeight = 1351
          // console.log("1500이상 hd: " + this.parallaxHeight)
        }
      }
    }
  }
}
