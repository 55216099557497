
export default {
  name: 'AssetGenerator',

  data() {
    return {}
  },

  methods: {
    getModal01() {
      this.$refs.generatorModal01.openModal()
    },

    getModal02() {
      this.$refs.generatorModal02.openModal()
    },

    getModal03() {
      this.$refs.generatorModal03.openModal()
    }
  }
}
