import { render, staticRenderFns } from "./AssetGenerator.vue?vue&type=template&id=8a12de80"
import script from "./AssetGenerator.vue?vue&type=script&lang=js"
export * from "./AssetGenerator.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MdGenerator01: require('/home/ubuntu/homepage_visualsyn/dist/components/main/modal/generator-01.vue').default,MdGenerator02: require('/home/ubuntu/homepage_visualsyn/dist/components/main/modal/generator-02.vue').default,MdGenerator03: require('/home/ubuntu/homepage_visualsyn/dist/components/main/modal/generator-03.vue').default})
