
import CesLogo from '@/assets/images/icon/ces-logo.svg?inline'
import SwLogo from '@/assets/images/icon/sxsw-logo.svg?inline'
export default {
  name: 'VisualMain',
  components: {
    CesLogo,
    SwLogo
  },
  data() {
    return {
      visual: {
        mImage: require('~/assets/images/main/top-key-visual-m.webp'),
        image: require('~/assets/images/main/top-key-visual.webp')
      }
    }
  },

  mounted() {
    this.mainAnimations()
  },

  methods: {
    mainAnimations() {
      const targets = [
        {
          animation: {
            targets: 'h1',
            scale: [1.5, 1],
            opacity: [0, 1],
            easing: 'easeOutExpo',
            duration: 200,
            delay: 600
          }
        },
        {
          animation: {
            targets: '.small-up',
            translateY: ['100%', '0%'],
            opacity: [0, 1],
            easing: 'linear',
            duration: 800
          }
        },
        {
          animation: {
            targets: '.btn-outline',
            opacity: [0, 1],
            easing: 'easeOutExpo',
            duration: 800
          }
        },
        {
          animation: {
            targets: '.sxsw-logo',
            opacity: [0, 1],
            easing: 'easeOutExpo',
            duration: 800
          }
        },
        {
          animation: {
            targets: '.ces-logo',
            opacity: [0, 1],
            easing: 'easeOutExpo',
            duration: 800
          }
        }
      ]

      const timeline = this.$anime.timeline({ loop: false })

      targets.forEach((target) => {
        timeline.add(target.animation)
      })
    }
  }
}
