
import LogoType from "~/assets/images/common/logo-white.svg?inline";

export default {
  name: "LogoWrap",

  components: {
    LogoType
  },

  props: {
    logoType: {
      type: String,
      required: true
    }
  }
};
