
export default {
  name: 'OneStep',

  data () {
    return {
      activeStep: 'step-01'
    };
  },

  computed: {
    getStepClass () {
      return {
        open01: this.activeStep === 'step-01',
        open02: this.activeStep === 'step-02',
        open03: this.activeStep === 'step-03'
      };
    }
  },

  methods: {
    addOpenClass (step) {
      this.activeStep = step;
    }
  }
}
