
import modalMixin from '@/plugins/modalMixin'

export default {
  name: 'Generator01',

  mixins: [modalMixin],

  data() {
    return {
      phoneBgKo: [
        {
          mImage: require('~/assets/images/main/modal-con-00-ko-m.webp'),
          image: require('~/assets/images/main/modal-con-00-ko.webp')
        },
        {
          mImage: require('~/assets/images/main/modal-con-01-ko-m.webp'),
          image: require('~/assets/images/main/modal-con-01-ko.webp')
        }
      ],

      phoneBgEn: [
        {
          mImage: require('~/assets/images/main/modal-con-00-en-m.webp'),
          image: require('~/assets/images/main/modal-con-00-en.webp')
        },
        {
          mImage: require('~/assets/images/main/modal-con-01-en-m.webp'),
          image: require('~/assets/images/main/modal-con-01-en.webp')
        }
      ],

      scanModel: [
        {
          mImage: require('~/assets/images/main/scan-model-01-m.webp'),
          image: require('~/assets/images/main/scan-model-01.webp')
        },
        {
          mImage: require('~/assets/images/main/scan-model-02-m.webp'),
          image: require('~/assets/images/main/scan-model-02.webp')
        }
      ],

      phoneFrame: {
        image: require('~/assets/images/main/phone-frame.webp')
      },

      movieObject: {
        localUrl:
          'https://cdn.finiroom.com/static/movie/compression-object.mp4',
        localUrl02:
          'https://cdn.finiroom.com/static/movie/compression-object.mp4',
        loop: '',
        autoplay: 'muted'
      },

      movieApartment: {
        localUrl:
          'https://cdn.finiroom.com/static/movie/compression-apartment.mp4',
        localUrl02:
          'https://cdn.finiroom.com/static/movie/compression-apartment.mp4',
        loop: '',
        autoplay: 'muted'
      },

      movieObjectEn: {
        localUrl:
          'https://cdn.finiroom.com/static/movie/compression-object-en.mp4',
        localUrl02:
          'https://cdn.finiroom.com/static/movie/compression-object-en.mp4',
        loop: '',
        autoplay: 'muted'
      },

      movieApartmentEn: {
        localUrl:
          'https://cdn.finiroom.com/static/movie/compression-apartment-en.mp4',
        localUrl02:
          'https://cdn.finiroom.com/static/movie/compression-apartment-en.mp4',
        loop: '',
        autoplay: 'muted'
      }
    }
  }
}
