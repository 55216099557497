
// import iconArrowDown from "~/assets/images/icon/icon-arrow-down.svg?inline";

export default {
  name: 'HeaderMenu',

  components: {
    // iconArrowDown
  },

  data() {
    return {
      menuOpen: false
    }
  },
  computed: {
    isMenuActive() {
      const activeLinks = this.$route.matched.filter((route) =>
        route.path.startsWith('/service')
      )
      return activeLinks.length > 0
    }
  },

  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    }
  }
}
