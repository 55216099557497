import { render, staticRenderFns } from "./HambergerMenu.vue?vue&type=template&id=3d37923a"
import script from "./HambergerMenu.vue?vue&type=script&lang=js"
export * from "./HambergerMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VsLogoWrap: require('/home/ubuntu/homepage_visualsyn/dist/components/common/footer/LogoWrap.vue').default,VsLanguage: require('/home/ubuntu/homepage_visualsyn/dist/components/common/header/Language.vue').default})
