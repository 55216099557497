
export default {
  name: 'CreatorMovie',

  data() {
    return {
      localUrl: 'https://cdn.finiroom.com/static/movie/creator-movie-02.webm',
      localUrl02: 'https://cdn.finiroom.com/static/movie/creator-movie-02.mp4',
      poster: require('~/assets/images/main/creator-movie-03-poster.webp')
    }
  }
}
