
import { defHeader } from '@/utils/head'

export default {
  name: 'IndexPage',

  data() {
    return {
      meta: {
        title: '',
        description:
          'Visualsyn - 내가 원하는 방식으로 메타버스 세계를 생성, 편집, 관리할 수 있는 플랫폼을 자유롭게 경험하세요',
        keyword:
          'metaverse, 메타버스, 메타버스 플랫폼, 3D assets, 3d 에셋, ai scan, ai 편집, 3D model, 3D스튜디오, 3d scan, 3d스캔, 비주얼신, Visualsyn',
        url: 'https://visualsyn.com/'
      }
    }
  },

  // meta info
  head() {
    const { title, description, keyword, url } = this.meta
    return defHeader(title, description, keyword, url)
  },

  methods: {}
}
