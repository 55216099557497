
import modalMixin from '@/plugins/modalMixin'

export default {
  name: 'Generator03',

  mixins: [modalMixin],

  data() {
    return {
      assetsImgKo: {
        mImage: require('~/assets/images/main/modal-con-03-ko-m.webp'),
        image: require('~/assets/images/main/modal-con-03-ko.webp')
      },

      assetsImgEn: {
        mImage: require('~/assets/images/main/modal-con-03-en-m.webp'),
        image: require('~/assets/images/main/modal-con-03-en.webp')
      }
    }
  }
}
