
export default {
  name: 'ContactUs',

  data: () => ({
    contact: {
      mImage: require('~/assets/images/main/contact-us-key-m.webp'),
      image: require('~/assets/images/main/contact-us-key.webp')
    }
  })
}
