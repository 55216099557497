
export default {
  name: 'AniViewer',

  data () {
    return {
      annotation: [
        {
          image: require("~/assets/images/main/annotation-01.webp")
        },
        {
          image: require("~/assets/images/main/annotation-02.webp")
        },
        {
          image: require("~/assets/images/main/annotation-03.webp")
        },
        {
          image: require("~/assets/images/main/annotation-04.webp")
        },
        {
          image: require("~/assets/images/main/annotation-05.webp")
        }
      ],

      showroom: {
        mImage: require("~/assets/images/main/temp-showroom-m.webp"),
        image: require("~/assets/images/main/temp-showroom.webp")
      },

      isAnimationTriggered: false
    };
  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll () {
      const imageWrap = this.$refs.imageWrap;
      if (imageWrap) {
        const rect = imageWrap.getBoundingClientRect();

        if (rect.top < window.innerHeight && rect.bottom >= 0 && !this.isAnimationTriggered) {
          this.isAnimationTriggered = true;
          this.showroomAnimations();
        }
      }
    },

    showroomAnimations () {
      // console.log("스크롤 다왔다")
      const targets = [
        {
          animation: {
            targets: '.main-showroom',
            scale: [1.5, 1],
            opacity: [0, 1],
            duration: 1000,
            easing: "easeOutExpo"
          }
        },
        {
          animation: {
            targets: '.main-showroom .layer-00',
            scale: [0, 1],
            opacity: {
              value: [0, 1],
              duration: 600
            },
            duration: 600,
            easing: "easeInOutQuad",
            offset: '-=1000'
          }
        },
        {
          animation: {
            targets: '.main-showroom .layer-01',
            scale: [0, 1],
            opacity: {
              value: [0, 1],
              duration: 600
            },
            duration: 600,
            easing: "easeOutExpo",
            offset: '-=550'
          }
        },
        {
          animation: {
            targets: '.main-showroom .layer-02',
            scale: [0, 1],
            opacity: {
              value: [0, 1],
              duration: 600
            },
            duration: 600,
            easing: "easeOutExpo",
            offset: '-=550'
          }
        },
        {
          animation: {
            targets: '.main-showroom .layer-03',
            scale: {
              value: [0.1, 1],
              duration: 800
            },
            opacity: [0, 1],
            translateX: ['30%', 0],
            translateY: ['60%', 0],
            duration: 500,
            easing: "easeOutExpo",
            offset: '-=550'
          }
        },
        {
          animation: {
            targets: '.main-showroom .layer-04',
            scale: {
              value: [0.1, 1],
              duration: 800
            },
            opacity: [0, 1],
            translateX: ['60%', 0],
            translateY: ['120%', 0],
            duration: 500,
            easing: "easeOutExpo",
            offset: '-=550'
          }
        }
      ];

      const timeline = this.$anime.timeline({ loop: false, autoplay: false });

      targets.forEach(target => {
        timeline.add(target.animation);
      });

      timeline.play(); // 애니메이션 실행
    }
  }
}
