import { render, staticRenderFns } from "./HeaderWrap.vue?vue&type=template&id=237f5ff3"
import script from "./HeaderWrap.vue?vue&type=script&lang=js"
export * from "./HeaderWrap.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VsHambergerMenu: require('/home/ubuntu/homepage_visualsyn/dist/components/common/header/HambergerMenu.vue').default,VsLogoWrap: require('/home/ubuntu/homepage_visualsyn/dist/components/common/footer/LogoWrap.vue').default,VsHeaderMenu: require('/home/ubuntu/homepage_visualsyn/dist/components/common/header/HeaderMenu.vue').default,VsLanguage: require('/home/ubuntu/homepage_visualsyn/dist/components/common/header/Language.vue').default})
