
export default {
  name: 'HeaderWrap',

  props: {
    headerType: {
      type: String,
      default: 'header-white'
    }
  },

  data() {
    return {
      // active: false
      active: true
    }
  },

  mounted() {
    // let prevScrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    // const navBar = document.getElementById('nav');
    //
    // window.addEventListener('scroll', () => {
    //   const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    //
    //   if (currentScrollPos === 0 || currentScrollPos < prevScrollPos) {
    //     this.active = false;
    //   } else {
    //     this.active = true;
    //   }
    //
    //   prevScrollPos = currentScrollPos;
    // });

    const navBar = document.getElementById('nav')

    window.addEventListener('scroll', () => {
      const currentScrollPos =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      if (currentScrollPos === 0) {
        this.active = true
      } else {
        this.active = false
      }
    })
  }
}
