
// noinspection ES6UnusedImports
import ArrowBottom from '~/assets/images/icon/icon-arrow-bottom.svg'

export default {
  name: 'HambergerMenu',

  components: {
    // ArrowBottom
  },

  data() {
    return {
      mobileMenuOpen: false
    }
  },

  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen
    }
  }
}
